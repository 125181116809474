$couleur_principale : #000;
$couleur_secondaire: #fff;
$blanc: #fff;

/* Général */
$body_background_color : #fff;
$content_background_color: $body_background_color;
$texte_lien:$couleur_principale;
$texte_lien_hover:$couleur_principale;
$header_background:#D8D8D8;
$slogan_color_texte:#333;
$titre_color:#666;
$couleur_text: #333;
$border-generales:#e1e1e1;

$bloc-fil-title-color:#999;
$bloc-fil-title-hover:$couleur_principale;
$bloc-fil-title-background:$header_background;

/* Menu */
$nav_background_color:rgba(0,0,0,.5);
$nav_border_color:$couleur_principale;

$li_background_color:transparent;
$li_background_color_hover:$couleur_principale;
$nav_texte_color:$blanc;
$nav_texte_color_hover:$blanc;

$sous_nav_background-color:$body_background_color;
$nav_sous_texte_color:$couleur_text;
$nav_sous_texte_color_hover:$texte_lien;

/* Produits */
$etiquette_produit_background:$couleur_principale;
$etiquette_produit_texte:#fff;
$fond_produits: $body_background_color;
$produits_text_color: $couleur_text;
$produits_text_color_hover: $texte_lien_hover;

/* Livre d'or */
$message_lo_color:#f3f2f2;

/* Blog / Boutons */
$btn_background_color:$couleur_principale;
$btn_texte_color:$blanc;
$btn_background_color_hover:$couleur_text;
$btn_texte_color_hover:$blanc;

/* Footer */
$footer_background_color:#333;
$footer_texte_color:#fff;

/* Colonne de gauche */
$colonne_gauche_background:#fff;
$colonne_gauche_link_background:$couleur_principale;
$colonne_gauche_link_color:$blanc;
$colone_gauche_border_color:$border-generales;
$colonne_gauche_sous_link_color:$couleur_text;
$colonne_gauche_sous_link_color_hover:$couleur_principale;

/* Template Panier */
$panier-background-color:transparent;
$panier-titre-color:#000;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

/* Flux panier */
$flux-panier_color-shadow:black;

@import "design/832series/declinaison";